const chains =
    {
       1: {
        name: 'Ethereum Mainnet'
       },
       2: {
        name: 'Expanse Network'
       },
       11155111: {
        name: 'Sepolia'
       }
    }

export default chains